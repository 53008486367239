<template>
  <div class="profile" v-if="!isProcessing">
    <profile-detail />
  </div>
</template>

<script>
import ProfileDetail from '@/components/profile/detail'

export default {
  name: 'profile',
  components: { ProfileDetail },
  async mounted () {
    // ユーザー情報の取得
    if (!this.user) await this.$store.dispatch('users/getUser', this.uid)

    // 存在しないユーザーであればnotfoundへ飛ばす
    if (!this.user) {
      this.$router.push({ name: 'notfound' })
      return
    }

    // 講師情報の取得
    if (!Object.keys(this.guests).length) this.$store.dispatch('guests/getGuests')

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$route.params.uid
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} 講師情報
     */
    guests () {
      return this.$store.getters['guests/guests']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.profile {
  width: 100vw;
  max-width: $max_width;
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0;
}
</style>
