<template>
  <div class="profile-detail">
    <!-- プロフィール画像 -->
    <div class="profile-detail__picture" id="picture">
      <v-img class="profile-detail__picture__icon" :src="user.icon" cover />
      <div class="profile-detail__picture__effect" />
      <div class="profile-detail__picture__info">
        <h2 class="profile-detail__picture__info__name">{{ user.name }}
          <v-icon class="profile-detail__picture__info__name__mark" v-if="isGuest">check_circle</v-icon>
        </h2>
        <h3 class="profile-detail__picture__info__position" v-if="user.position" >{{ user.position }}</h3>
      </div>
    </div>
    <!-- プロフィール文 -->
    <p class="profile-detail__description">{{ user.profile }}</p>
    <a class="profile-detail__link" v-if="user.link" target="_blank" rel="noopener noreferrer" :href="user.link">
      <v-icon class="profile-detail__link__icon">insert_link</v-icon>
      <span class="profile-detail__link__description">{{ removeScheme(user.link) }}</span>
    </a>
  </div>
</template>

<script>
import urllib from '@/assets/lib/url'

export default {
  mixins: [urllib],
  mounted () {
    // スマホのときはアドレスバー分高さがずれるので、修正する
    // document.getElementByIdは画面描画後じゃないとnullが返ってくるのでsetProcessing=falseの後に持ってくる
    const size = require('@/assets/sass/size.scss')
    const headerHeight = parseInt(size.header_height)
    const innerHeight = window.innerHeight
    document.getElementById('picture').style.height = (innerHeight - headerHeight) + 'px'
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$route.params.uid
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
       * @return {Object} 講師情報
       */
    guests () {
      return this.$store.getters['guests/guests']
    },
    /**
     * @return {Boolean} ゲストかどうか
     */
    isGuest () {
      const set = new Set(Object.keys(this.guests))
      return set.has(this.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.profile-detail {
  width: 100%;
  &__picture {
    position: relative;
    width: 100vw;
    max-width: $max_width;
    height: calc(100vh - #{$header_height});
    margin-left: -$padding_width;
    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $gray_lighten_color;
    }
    &__effect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgb(255 255 255 / 0%) 46.88%, #fff 100%);
    }
    &__info {
      position: absolute;
      bottom: $padding_height;
      left: $padding_width;
      &__name {
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        font-weight: bold;
        color: $gray_darken_color;
        &__mark {
          width: 24px;
          height: 24px;
          margin-left: $unit_size;
          color: $concept_color;
        }
      }
      &__position {
        margin-top: $unit_size;
        font-size: 1.2rem;
        font-weight: bold;
        color: $gray_darken_color;
      }
    }
  }
  &__description {
    margin: $unit_size*4 0 0;
    font-size: 1.4rem;
    white-space: pre-wrap;
  }
  &__link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: $unit_size*4 0 0;
    font-size: 1.4rem;
    color: $green_color !important;
    &__icon {
      margin-right: $unit_size;
      color: $gray_darken_color;
    }
    &__description {
      max-width: 100%;
      word-wrap: break-word;
    }
  }
}
</style>
